import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import Dealers from '../../dealers'

const DealerScreen = () => {
    return (
        <>
            <Meta title = "Bayiler - Yem Katkı Maddeleri, Premiksler" description = "Biotem yem katkı maddeleri, premiksler ve hayvan sağlığı ürünleri üreticisidir. Yem katkı maddeleri, premiks, tavuk vitamini ve daha fazlası." keywords = "yem katkı maddeleri, yem katkısı, premiks, tavuk vitamini" />
            
            <Header />

            <Container className='customDealerContainer'>
                <Row className='customRow-3'>
                    <Col xl={12} className='headerCol'>
                        <h1 className='header-1'>BAYİLER</h1>
                    </Col>  
                </Row>

                {Dealers.map( dealer => 
                    <Row className='dealerRow'>
                        <Col xs={12} sm={12} md={12} lg={2} xl={2} className='dealerCol'>
                            <p className='dealerCity'>{dealer.city}</p>
                        </Col>
                        <Col xs={4} sm={4} md={2} lg={1} xl={1} className='dealerCol'>
                            <Image src={dealer.image} alt='Ecza Deposu Logo' className='dealerImage' fluid/>
                        </Col>
                        <Col xs={8} sm={8} md={5} lg={3} xl={3} className='dealerCol'>
                            <p className='dealerP'>{dealer.name}</p>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={3} xl={3} className='dealerCol'>
                            <p className='dealerP-2'>Adres: </p>
                            <p className='dealerP'>{dealer.address}</p>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3} className='dealerCol-2'>
                            <p className='dealerP-2'>Telefon: </p>
                            <p className='dealerP'>{dealer.phone}</p>
                        </Col>
                    </Row>
                )} 
            </Container>

            <Footer />
        </>
    )
}

export default DealerScreen