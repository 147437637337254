import React from 'react'
import 'animate.css'
import { Button, Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AdvertisementCarousel = () => {
    return (
        <section className='carouselSection'>
            <Carousel interval={5000} controls={false} pause={false} indicators={true}>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:900px)' srcset='/images/tr/anasayfa/4.jpg' />
                        <source media='(min-width:525px)' srcset='/images/tr/anasayfa/3(2).jpg' />
                        <Image src='/images/tr/anasayfa/4(3).jpg' className='carouselImage' alt='arı yem katkı maddeleri' fluid/>
                    </picture>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:900px)' srcset='/images/tr/anasayfa/3.jpg' />
                        <source media='(min-width:525px)' srcset='/images/tr/anasayfa/3(2).jpg' />
                        <Image src='/images/tr/anasayfa/3(3).jpg' className='carouselImage' alt='arı yem katkı maddeleri' fluid/>
                    </picture>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:900px)' srcset='/images/tr/anasayfa/1.jpg' />
                        <source media='(min-width:525px)' srcset='/images/tr/anasayfa/1(2).jpg' />
                        <Image src='/images/tr/anasayfa/1(3).jpg' className='carouselImage' alt='kedi köpek yem katkı maddeleri' fluid/>
                    </picture>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:900px)' srcset='/images/tr/anasayfa/2.jpg' />
                        <source media='(min-width:525px)' srcset='/images/tr/anasayfa/2(2).jpg' />
                        <Image src='/images/tr/anasayfa/2(3).jpg' className='carouselImage' alt='kanatlı tavuk yem katkı maddeleri' fluid/>
                    </picture>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}

export default AdvertisementCarousel