import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Nav, Navbar, Image } from 'react-bootstrap'

const Header = () => {
    return (
        <header>
            <section className='freeShipping'>
                <p className='freeShipping'>2000 TL ve Üzeri Alışverişlerde Kargo Ücretsiz</p>
            </section>
            <Navbar expand='lg'>
                <Container className='customContainer'>
                    <LinkContainer to='/'>
                        <Navbar.Brand>
                            <Image src='/images/biotem-logo.png' className='logo' alt='Biotem Logo'/>
                        </Navbar.Brand>
                    </LinkContainer>
                        
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className='ms-auto'>
                            <LinkContainer to='/'>
                                <Nav.Link id='headerItem'>ANASAYFA</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/kurumsal/'>
                                <Nav.Link id='headerItem'>KURUMSAL</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/urunler/'>
                                <Nav.Link id='headerItem'>ÜRÜNLER</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/bayiler/'>
                                <Nav.Link id='headerItem'>BAYİLER</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/iletisim/'>
                                <Nav.Link id='headerItem'>İLETİŞİM</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header