import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image} from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import AdvertisementCarousel from '../components/AdvertisementCarousel'
import Categories from '../../categories'
import References from '../../references'
import Certificates from '../../certificates'

const HomeScreen = () => {
    return (
        <>
            <Meta title = "Yem Katkı Maddeleri, Premiksler - Biotem" description = "Biotem yem katkı maddeleri, premiksler ve hayvan sağlığı ürünleri üreticisidir. Yem katkı maddeleri, premiks, tavuk vitamini ve daha fazlası." keywords = "yem katkı maddeleri, yem katkısı, premiks, tavuk vitamini" />
            
            <Header />

            <AdvertisementCarousel />

            <Container>
                <Row className='customRow'>
                    <Col xl={12} className='homeCol'>
                        <h1 className='homeHeader'>Biotem Yem Katkı Maddeleri</h1>
                        <Row>
                        {Categories.map( category =>
                        category.topCategoryId === null ?
                        <Col xs={6} sm={6} md={4} lg={2} xl={2} className='categoryCol-3' key={category.nameTr}>
                            <Link to={`/urunler/${category.urlTr}/`} className='textDecorationNone'>
                                <Image src={category.imageTr} alt={category.nameTr + ' Yem Katkı Maddeleri'} className='categoryImage-3' fluid/>    
                                <h2 className='categoryHeader'>{category.nameTr}</h2>
                            </Link>
                        </Col>
                        : null
                        )}  
                        </Row>
                    </Col>
                </Row>
            </Container>

            <section className='homeSection'>
                <Container>
                    <Row className='customRow'>
                        <Col xl={12} className='homeCol-2'>
                            <h2 className='homeHeader-2'>Yem Katkı Maddeleri Nedir?</h2>
                            <p className='homeP-2'>Yem katkı maddeleri hayvanların besin maddesi ihtiyaçlarını karşılayan, doğal, taze, korunmuş halde olan bitkisel veya hayvansal orijinli ürünler ve bunlardan endüstriyel işleme sonucu elde edilen ürünler ile hayvanların ağızdan beslenmesi amacıyla, premikslerde taşıyıcı olarak ya da karma yemlerin hazırlanmasında doğrudan ya da işlenerek kullanılan, yem katkı maddesi içeren ya da içermeyen organik veya inorganik maddeleri ifade eder.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container>
                <Row className='customRow-4'>
                    <h2 className='homeHeader-2'>SERTİFİKALAR</h2>
                    {Certificates.map( certificate => 
                        <Col xs={6} sm={4} md={3} lg={3} xl={3} key={certificate.name}>
                            <Image src={certificate.image} alt={certificate.name} fluid/>
                        </Col>
                    )} 
                </Row>
                <Row className='customRow-2'>
                    <h2 className='homeHeader-2'>REFERANSLAR</h2>
                    {References.map( reference => 
                        <Col xs={6} sm={4} md={3} lg={3} xl={2} key={reference.name}>
                            <Image src={reference.image} alt={reference.name} fluid/>
                        </Col>
                    )}
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default HomeScreen