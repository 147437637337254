const categories = [
    {
        id: 'M01',
        topCategoryId: null,
        urlTr: 'kanatli-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/kanatli-yem-katki-maddeleri.png',
        nameTr: 'Kanatlı',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: null,
    },
    {
        id: 'M02',
        topCategoryId: null,
        urlTr: 'pet-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/pet-yem-katki-maddeleri.png',
        nameTr: 'Pet',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: [ '/images/tr/kategoriler/naf-pati-pet-yem-katki-maddeleri.png',
                           '/images/tr/kategoriler/naf-pati-pet-yem-katki-maddeleri-2.jpg'],
    },
    {
        id: 'M03',
        topCategoryId: null,
        urlTr: 'ari-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/ari-yem-katki-maddeleri.png',
        nameTr: 'Arı',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: [ '/images/tr/kategoriler/naf-bee-ari-yem-katki-maddeleri.png',
                           '/images/tr/kategoriler/naf-bee-ari-yem-katki-maddeleri-2.jpg'],
    },
    {
        id: 'M04',
        topCategoryId: null,
        urlTr: 'ruminant-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/ruminant-yem-katki-maddeleri.png',
        nameTr: 'Ruminant',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: null,
    },
    {
        id: 'M05',
        topCategoryId: null,
        urlTr: 'at-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/at-yem-katki-maddeleri.png',
        nameTr: 'At',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: null,
    },
    {
        id: 'M06',
        topCategoryId: null,
        urlTr: 'balik-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/balik-yem-katki-maddeleri.png',
        nameTr: 'Balık',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: [],
    },
    {
        id: 'C0101',
        topCategoryId: 'M01',
        urlTr: 'broyler-ve-yumurta-tavugu-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/broyler-ve-yumurta-tavugu-yem-katki-maddeleri.jpg',
        nameTr: 'Broyler ve Yumurta Tavuğu',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: [ '/images/tr/kategoriler/nafoil-broyler-ve-yumurta-tavugu-yem-katki-maddeleri.png',
                           '/images/tr/kategoriler/nafoil-broyler-ve-yumurta-tavugu-yem-katki-maddeleri-2.jpg'],
    },
    {
        id: 'C0102',
        topCategoryId: 'M01',
        urlTr: 'ozel-kanatli-yem-katki-maddeleri',
        imageTr: '/images/tr/kategoriler/ozel-kanatli-yem-katki-maddeleri.jpg',
        nameTr: 'Özel Kanatlı',
        descriptionTr: null,
        keywordsTr: null,
        additionalImage: [ '/images/tr/kategoriler/naf-poultry-ozel-kanatli-yem-katki-maddeleri.png',
                           '/images/tr/kategoriler/naf-poultry-ozel-kanatli-yem-katki-maddeleri-2.jpg'],
    },
]
  
export default categories