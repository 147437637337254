import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col, Image } from 'react-bootstrap'

const AboutScreen = () => {
    return (
        <>
            <Meta title = "Kurumsal - Yem Katkı Maddeleri, Premiksler" description = "Biotem yem katkı maddeleri, premiksler ve hayvan sağlığı ürünleri üreticisidir. Yem katkı maddeleri, premiks, tavuk vitamini ve daha fazlası." keywords = "yem katkı maddeleri, yem katkısı, premiks, tavuk vitamini" />

            <Header />

            <Container>
                <Row className='customRow'>
                    <Col xl={12} className='headerCol'>
                        <h1 className='header-1'>KURUMSAL</h1>
                    </Col>
                    <Col xl={12} className='aboutCol'>
                        <p className='aboutP'>2010 yılında İstanbul’da %100 yerli sermaye ile kurulan bir firmayız. Biotem ailesi olarak bu yolculuğa yaşam kaynağı olan suyu temel alarak başladık. Hijyen alanında bilimsel ve teknolojik araştırmalar yaparak “İnsan Hijyeni, Hava, Yer ve Yüzey Hijyeni, Havuz Hijyeni, Veteriner, Gıda ve Yem Alanı Hijyeni” olmak üzere, 5 farklı kategoride kullanılabilir biyosidal ürün ruhsatını T.C. Sağlık Bakanlığı Türkiye Halk Sağlığı Kurumu’ndan aldık. Ürünlerimizin kalitesine önce kendimiz sonra ise insan ve toplum sağlığına faydalı olabileceğine inanarak yolumuza devam ettik. Ürünlerimizin hem çok yönlü kullanım alanlarıyla, hem de uygun fiyat avantajı sayesinde, diğer biyosidal dezenfektan ürünlerine göre farklı olduğumuzu ortaya koyduk.</p>
                        <p className='aboutP'>Gelişmeyi ve ilerlemeyi amaç edinmiş bir firma olarak, kanatlı yem sektöründeki ihtiyaçlara çözüm olabileceğimizi düşündük. Bu kapsamda önce sektörün ihtiyaç ve beklentilerini sonrasında ise, bizim bu sektöre neler sunabileceğimizi araştırdık. Yaptığımız fizibilite çalışmaları sonrasında ürün geliştirebilmek için, birçok deneme ve test yaptırdık. Bu amaçla, uygulamalı olarak da danışman hocalarımızdan aldığımız destekle, Bolu’da özel Ar-Ge kümesleri kurdurarak geliştirdiğimiz ürünlerimizi bilimsel olarak test ettik. Kümeslerdeki yapmış olduğumuz araştırmalar sonrasında, kanatlı yem sektörü için geliştirdiğimiz alternatif yem premikslerinin faydalı olabileceğini ispat ettik.</p>
                        <p className='aboutP'>Çağdaş bir şirket olarak; ürettiğimiz ürünler için önce Ar-Ge denemeleri yaparak, kaliteli ürünler üretmekle beraber müşterilerimizin ihtiyaç ve beklentilerini karşılamayı prensip haline getirdik. Her geçen gün müşterilerimize hep daha iyisini sunabilmek için kalitemizi, kapasitemizi, teknolojimizi ve ürün çeşitlerimizi geliştirmeye yönetik bitmek tükenmek bilmeyen azimle çalışmalarımıza ve yatırımlarımıza devam etmekteyiz.</p>
                        <p className='aboutP'><strong>Biotem Biyosid Dezenfektan Hijyen Dezenfeksiyon Sağlık ve Temizlik Hizmetleri Sanayi İç ve Dış Ticaret Limited</strong></p>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default AboutScreen