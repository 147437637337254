const certificates = [
    {
        image: '/images/tr/sertifikalar/sertifika-4.png',
        name: 'iso 22716 logo'
    },
    {
        image: '/images/tr/sertifikalar/sertifika-3.png',
        name: 'eco friendly logo'
    },
    {
        image: '/images/tr/sertifikalar/sertifika.png',
        name: 'iso 9001:2015 logo'
    },
    {
        image: '/images/tr/sertifikalar/sertifika-2.png',
        name: 'iso 22000:2005 logo'
    },
]
  
export default certificates 