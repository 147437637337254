import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Categories from '../../categories'

const CategoryScreen = () => {
    return (
        <>
            <Meta title = "Ürünler - Yem Katkı Maddeleri, Premiksler" description = "Biotem yem katkı maddeleri, premiksler ve hayvan sağlığı ürünleri üreticisidir. Yem katkı maddeleri, premiks, tavuk vitamini ve daha fazlası." keywords = "yem katkı maddeleri, yem katkısı, premiks, tavuk vitamini" />

            <Header />

            <Container>
                <Row className='categoryRow-2'>
                    <Col xl={12} className='headerCol'>
                        <h1 className='header-1'>ÜRÜNLER</h1>
                    </Col>

                    {Categories.map( category => category.topCategoryId === null ?
                        <Col xs={6} sm={6} md={4} lg={4} xl={4} className='categoryCol-2' key={category.nameTr}>
                            <Link to={`/urunler/${category.urlTr}/`} className='textDecorationNone'>
                                <Image src={category.imageTr} alt={category.nameTr + ' Yem Katkı Maddeleri'} className='categoryImage-3' fluid/>    
                                <h2 className='categoryHeader'>{category.nameTr}</h2>
                            </Link>
                        </Col>
                        : null)}  
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default CategoryScreen