const dealers = [
    {
        name: 'Seymen Ecza Deposu',
        city: 'ANKARA',
        address: 'Mutlukent Mahallesi, 1978 Caddesi, No: 12, Çankaya/Ankara',
        phone: '+90 312 350 98 98',
        image: '/images/tr/bayiler/seymen-ecza-deposu-logo.png'
    },
    {
        name: 'Vera Veteriner Ecza Deposu',
        city: 'BOLU',
        address: 'Karamanlı Mahallesi, Yeşil Sokak, No: 11A/B, Merkez/Bolu',
        phone: '+90 374 222 80 80',
        image: '/images/tr/bayiler/veravet-logo.png'
    },
    {
        name: 'Gür Arıcılık',
        city: 'ERZURUM',
        address: 'Kazım Karabekir Paşa Mahallesi, Nazik Çarşı Caddesi, No: 29, Yakutiye/Erzurum',
        phone: '+90 543 213 42 82',
        image: '/images/tr/bayiler/gur-aricilik-logo.png'
    },
    {
        name: 'Başoğlu Arıcılık',
        city: 'GİRESUN',
        address: 'Çarşı Mahallesi, Çevre Yolu Sokak, No: 8, Tirebolu/Giresun',
        phone: '+90 850 666 0 111',
        image: '/images/tr/bayiler/basoglu-aricilik-logo.png'
    },
    {
        name: 'İstanbul Petek Arıcılık Evi',
        city: 'İSTANBUL',
        address: 'Fatih Mahallesi, Cumhuriyet Caddesi, No: 329, Beykoz/İstanbul',
        phone: '+90 216 479 18 48',
        image: '/images/tr/bayiler/istanbul-petek-aricilik-evi-logo.png'
    },
    {
        name: 'Mebu Kimya Gıda',
        city: 'İZMİR',
        address: 'Fevzipaşa Mahallesi, 846. Sokak, No: 51, Katipzade Vakıf İş Merkezi, Kat: 4, Daire: 409, Kemeraltı - Konak/İzmir',
        phone: '+90 232 335 19 23',
        image: '/images/tr/bayiler/mebu-kimya-gida-logo.png'
    },
    {
        name: 'Arma Arı ve Arıcı Malzemeleri',
        city: 'İZMİR',
        address: 'Koşukavak Mahallesi, Abdi İpekçi Cadddesi, No: 35/C, Bornova/İzmir',
        phone: '+90 532 211 38 82',
        image: '/images/tr/bayiler/arma-ari-ve-arici-malzemeleri-logo.png'
    },
    {
        name: 'Ege Ecza Deposu',
        city: 'İZMİR',
        address: 'Osmangazi Mahallesi, İbrahim Ethem Caddesi, No: 72/A, Bayraklı/İzmir',
        phone: '+90 232 341 13 73',
        image: '/images/tr/bayiler/ege-ecza-deposu-logo.png'
    },
    {
        name: 'Alşan Ecza Deposu',
        city: 'KOCAELİ',
        address: 'Esentepe Mahallesi, Hamit Kaplan Caddesi, No: 185, Körfez/Kocaeli',
        phone: '+90 262 527 00 46',
        image: '/images/tr/bayiler/alsan-ecza-deposu-logo.png'
    },
    {
        name: 'Doğuş Veteriner Kliniği',
        city: 'KONYA',
        address: 'Fevziçakmak Mahallesi, 10505. Sokak, Karatay/Konya',
        phone: '+90 332 342 50 07',
        image: '/images/tr/bayiler/dogus-logo.png'
    },
    {
        name: 'Gümüş Petek Arıcılık',
        city: 'ORDU',
        address: 'Bucak Mahallesi, 361. Sokak, No: 17/3, Altınordu/Ordu',
        phone: '+90 452 225 67 59',
        image: '/images/tr/bayiler/gumus-petek-aricilik-logo.png'
    },
    {
        name: 'Den-Ge Ecza Deposu',
        city: 'SAKARYA',
        address: 'Erenler Mahallesi, 1165. Sokak, No: 6, Erenler/Sakarya',
        phone: '+90 264 279 35 33',
        image: '/images/tr/bayiler/denge-ecza-deposu-logo.png'
    },
]
  
export default dealers 