import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image, Button, Breadcrumb } from 'react-bootstrap'
import Categories from '../../categories'
import Products from '../../products'

const ProductsScreen = ( { match } ) => {

    var isCategory = false
    var topCategory = null

    const category = Categories.find( category => category.urlTr === match.params.id)

    if(category !== undefined){
        isCategory = true

        if(category.topCategoryId !== null){
            topCategory = Categories.find( ctgry => ctgry.id === category.topCategoryId)
        }
    }

    return (
        <>
            {isCategory === true ? 
                <Meta title = {category.nameTr + " Yem Katkı Maddeleri, Premiksler - Biotem"} description = "Biotem yem katkı maddeleri, premiksler ve hayvan sağlığı ürünleri üreticisidir. Yem katkı maddeleri, premiks, tavuk vitamini ve daha fazlası." keywords = "yem katkı maddeleri, yem katkısı, premiks, tavuk vitamini" />
            : null}

            <Header />

            <Container>
                {isCategory === true ?
                <Row className='categoryRow'>
                    <Col xl={12} className='headerCol'>
                        <h1 className='header-1'>{category.nameTr} Yem Katkı Maddeleri</h1>
                    </Col>

                    <Col xl={12} className='breadcrumbCol-2'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to='/'>
                                    <i className="fas fa-home" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/urunler/'>
                                    Ürünler
                                </Link>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    
                    {category.id === 'M06' ? 
                        <Col xl={12} className='productSoon'>
                            <p className='productErrorP'>Çok Yakında Satışta</p>
                        </Col> : null}

                    {Categories.map( subCategory =>
                        <>
                            {subCategory.topCategoryId === category.id ? 
                                <Col xs={12} sm={6} md={6} lg={6} xl={6} className='categoryCol-4'>
                                    <Link to={`/urunler/${subCategory.urlTr}/`} className='textDecorationNone'>
                                        <Col className='categoryItem-2'>
                                            <Image src={subCategory.imageTr} alt={subCategory.nameTr + " Yem Katkı Maddeleri"} 
                                            className='categoryImage-4' fluid/>    

                                            <h2 className='categoryHeader-4'>
                                                {subCategory.nameTr}
                                            </h2>
                                        </Col>
                                    </Link>
                                </Col>
                            : null}
                        </>
                    )}

                    {Products.map( product => 
                        <>
                            {product.category === category.id ? 
                                <Col xs={6} sm={6} md={4} lg={4} xl={3} className='categoryCol'>
                                    <Link to={`/urun/${product.urlTr}/`} className='textDecorationNone'>
                                        <Col className='categoryItem-2'>
                                            <Image src={product.imagesTr[0]} alt={category.nameTr + " Yem Katkı Maddeleri"} 
                                            className='categoryImage-2' fluid/>    

                                            <h2 className='categoryHeader-2'>
                                                {product.nameTr}
                                            </h2>
                                            <p className='categoryOverview'>{product.descriptionTr}</p>
                                            <h3 className='categoryHeader-3'>
                                                {product.priceTr}
                                            </h3>
                                            <Button className='categoryButton'>
                                                Ürünü İncele
                                            </Button>
                                        </Col>
                                    </Link>
                                </Col>
                            : null}
                        </>
                    )}
                    
                    {category.descriptionTr === null ? null : 
                        <Col xl={12} className='categoryCol'>
                            <Col xl={12} className='categoryDescriptionCol'>
                                <p className='categoryDescriptionP'>{category.descriptionTr}</p>
                            </Col>
                        </Col>}           
                </Row>

                :

                <Row className='errorRow'>
                    <Col xl={12} className='productError'>
                        <p className='productErrorP'>Sayfa Bulunamadı.</p>
                        <p className='error404P'>404</p>
                    </Col>
                </Row>
                }
            </Container>

            <Footer />
        </>
    )
}

export default ProductsScreen