import React from 'react'
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

import HomeScreenTr from './tr/screens/HomeScreen'
import AboutScreenTr from './tr/screens/AboutScreen'
import CategoryScreenTr from './tr/screens/CategoryScreen'
import ProductsScreenTr from './tr/screens/ProductsScreen'
import ProductScreenTr from './tr/screens/ProductScreen'
import DealerScreenTr from './tr/screens/DealerScreen'
import ContactScreenTr from './tr/screens/ContactScreen'

import ErrorScreen from './ErrorScreen'

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path='/' component={HomeScreenTr} />
                <Route exact path='/kurumsal/' component={AboutScreenTr} />
                <Route exact path='/urunler/' component={CategoryScreenTr} />
                <Route exact path='/urunler/:id/' component={ProductsScreenTr} />
                <Route exact path='/urun/:id/' component={ProductScreenTr} />
                <Route exact path='/bayiler/' component={DealerScreenTr} />
                <Route exact path='/iletisim/' component={ContactScreenTr} />

                <Route component={HomeScreenTr} />
            </Switch>
        </Router>
    )
}

export default App