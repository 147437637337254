import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'

const ContactScreen = () => {
    return (
        <>
            <Meta title = "İletişim - Yem Katkı Maddeleri, Premiksler" description = "Biotem yem katkı maddeleri, premiksler ve hayvan sağlığı ürünleri üreticisidir. Yem katkı maddeleri, premiks, tavuk vitamini ve daha fazlası." keywords = "yem katkı maddeleri, yem katkısı, premiks, tavuk vitamini" />
            
            <Header />

            <Container>
                <Row className='customRow'>
                    <Col xl={12} className='headerCol'>
                        <h1 className='header-1'>İLETİŞİM</h1>
                    </Col>
                    
                    <Col lg={4} xl={4} className='contactCol'>
                        <p className='contactItem'>
                            <i className="fas fa-info-circle fa-lg" />&nbsp;&nbsp;Biotem Biyosid Dezenfektan Hijyen Dezenfeksiyon Sağlık ve Temizlik Hizmetleri Sanayi İç ve Dış Ticaret Limited
                        </p>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <p className='contactItem'>
                            <i className="fas fa-map-marker-alt fa-lg" />&nbsp;&nbsp;Dumlupınar Mahallesi, Tevfik İleri Caddesi, No: 51, Pendik/İstanbul, 34896
                        </p>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <p className='contactItem'>
                            <i className="far fa-clock fa-lg" />&nbsp;&nbsp;Pazartesi-Cuma: 08:30-18:30, Cumartesi: 09:00-14:00
                        </p>
                    </Col>


                    <Col lg={4} xl={4} className='contactCol'>
                        <a href="mailto:info@biotemltd.com" className='contactItem'>
                            <i className="far fa-envelope fa-lg mailIcon" />&nbsp;&nbsp;info@biotemltd.com
                        </a>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <a href="tel:+902163790101" className='contactItem'>
                            <i className="fas fa-phone fa-lg phoneIcon" />&nbsp;&nbsp;+90 216 379 01 01
                        </a>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <a href="https://api.whatsapp.com/send?phone=905435607156" target="_blank" rel="noopener noreferrer" className='contactItem'>
                            <i className="fab fa-whatsapp fa-xl wpIcon" />&nbsp;&nbsp;+90 543 560 71 56
                        </a>
                    </Col>

                    <Col xl={12} className='contactCol'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12065.633488075535!2d29.2445827!3d40.8848561!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbb9571440193dc74!2sBiotem%20Yem%20Katk%C4%B1%20Maddeleri!5e0!3m2!1str!2str!4v1652153726589!5m2!1str!2str" title='googleMap' />
                    </Col>  
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default ContactScreen