const references = [
    {
        image: '/images/tr/referanslar/erpilic-logo.jpg',
        name: 'erpilic logo'
    },
    {
        image: '/images/tr/referanslar/gedik-pilic-logo.jpg',
        name: 'gedik pilic logo'
    },
    {
        image: '/images/tr/referanslar/banvit-logo.jpg',
        name: 'banvit logo'
    },
    {
        image: '/images/tr/referanslar/senpilic-logo.jpg',
        name: 'senpilic logo'
    },
    {
        image: '/images/tr/referanslar/akpilic-logo.jpg',
        name: 'akpilic logo'
    },
    {
        image: '/images/tr/referanslar/cp-gida-logo.jpg',
        name: 'cp gida logo'
    },
]
  
export default references 