import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'

const Footer = () => {
    return (
        <footer>
            <Container className='customContainer'>
                <Row>
                    <Col xl={4}>
                        <Col className='footerCol'>
                            <p className='footerItem'>
                                <i className="fas fa-info-circle fa-lg" />
                                &nbsp;&nbsp;Biotem Biyosid Dezenfektan Hijyen Dezenfeksiyon Sağlık ve Temizlik Hizmetleri Sanayi İç ve Dış Ticaret Limited
                            </p>
                        </Col>
                        <Col className='footerCol'>
                            <p className='footerItem'>
                                <i className="fas fa-map-marker-alt fa-lg" />
                                &nbsp;&nbsp;Dumlupınar Mahallesi, Tevfik İleri Caddesi, No: 51, Pendik/İstanbul, 34896
                            </p>
                        </Col>
                    </Col>

                    <Col xl={4}>
                        <Col className='footerCol'>
                            <a href="mailto:info@biotemltd.com" className='footerItem'>
                                <i className="far fa-envelope fa-lg" />
                                &nbsp;&nbsp;info@biotemltd.com
                            </a>    
                        </Col>
                        <Col className='footerCol'>
                            <a href="tel:+902163790101" className='footerItem'>
                                <i className="fas fa-phone fa-lg" />
                                &nbsp;&nbsp;+90 216 379 01 01
                            </a>
                        </Col>
                        <Col className='footerCol'>
                            <a href="https://api.whatsapp.com/send?phone=905435607156" target="_blank" rel="noopener noreferrer" className='footerItem'>
                                <i className="fab fa-whatsapp fa-xl" />
                                &nbsp;&nbsp;+90 543 560 71 56
                            </a>  
                        </Col>                       
                    </Col>

                    <Col xl={4}>
                        <Col className='footerCol'>
                            <p className='footerItem'>Biotem 2010 yılında İstanbul'da %100 yerli sermaye ile kurulan yem katkı maddeleri, premiksler ve hayvan sağlığı ürünleri üreticisidir. Yem katkı maddeleri, premiks, tavuk vitamini ve daha fazlası.</p>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer